/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCohortToProgram
// ====================================================

export interface AddCohortToProgram_addCohortToProgram {
  __typename: "Cohort";
  id: string;
}

export interface AddCohortToProgram {
  addCohortToProgram: AddCohortToProgram_addCohortToProgram;
}

export interface AddCohortToProgramVariables {
  cohort: AddCohortToProgramInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddRegistrationQuestion
// ====================================================

export interface AddRegistrationQuestion_addRegistrationQuestion {
  __typename: "Cohort";
  id: string;
}

export interface AddRegistrationQuestion {
  addRegistrationQuestion: AddRegistrationQuestion_addRegistrationQuestion;
}

export interface AddRegistrationQuestionVariables {
  cohortId: string;
  registrationQuestion: RegistrationQuestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchiveCohort
// ====================================================

export interface ArchiveCohort_archiveCohort {
  __typename: "Cohort";
  id: string;
}

export interface ArchiveCohort {
  archiveCohort: ArchiveCohort_archiveCohort;
}

export interface ArchiveCohortVariables {
  cohort: ArchiveCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteRegistrationQuestion
// ====================================================

export interface DeleteRegistrationQuestion_deleteRegistrationQuestion {
  __typename: "Cohort";
  id: string;
}

export interface DeleteRegistrationQuestion {
  deleteRegistrationQuestion: DeleteRegistrationQuestion_deleteRegistrationQuestion;
}

export interface DeleteRegistrationQuestionVariables {
  cohortId: string;
  registrationQuestionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DuplicateCohort
// ====================================================

export interface DuplicateCohort_duplicateCohort {
  __typename: "Cohort";
  id: string;
}

export interface DuplicateCohort {
  duplicateCohort: DuplicateCohort_duplicateCohort;
}

export interface DuplicateCohortVariables {
  cohort: DuplicateCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllOpenCohorts
// ====================================================

export interface GetAllOpenCohorts_getAllOpenCohorts_program {
  __typename: "Program";
  name: string;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_developmentUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_implementationUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_evaluationUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_developmentSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_evaluationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohorts_getAllOpenCohorts_implementationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohorts_getAllOpenCohorts {
  __typename: "Cohort";
  id: string;
  internalLabel: string | null;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  archived: boolean;
  registrationCloseDate: any | null;
  registrationOpenDate: any | null;
  preRegistrantsCount: number | null;
  newRegistrantsCount: number | null;
  registeredCount: number | null;
  waitlistCount: number | null;
  deniedCount: number | null;
  nextSessionDate: string | null;
  program: GetAllOpenCohorts_getAllOpenCohorts_program | null;
  developmentUser: GetAllOpenCohorts_getAllOpenCohorts_developmentUser | null;
  implementationUser: GetAllOpenCohorts_getAllOpenCohorts_implementationUser | null;
  evaluationUser: GetAllOpenCohorts_getAllOpenCohorts_evaluationUser | null;
  developmentSupportUser: (GetAllOpenCohorts_getAllOpenCohorts_developmentSupportUser | null)[] | null;
  evaluationSupportUser: (GetAllOpenCohorts_getAllOpenCohorts_evaluationSupportUser | null)[] | null;
  implementationSupportUser: (GetAllOpenCohorts_getAllOpenCohorts_implementationSupportUser | null)[] | null;
}

export interface GetAllOpenCohorts {
  getAllOpenCohorts: GetAllOpenCohorts_getAllOpenCohorts[] | null;
}

export interface GetAllOpenCohortsVariables {
  status: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllOpenCohortsForUser
// ====================================================

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_program {
  __typename: "Program";
  name: string;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_developmentUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_implementationUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_evaluationUser {
  __typename: "User";
  id: string;
  fullName: string | null;
  email: string;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_developmentSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_evaluationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser_implementationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetAllOpenCohortsForUser_getAllOpenCohortsForUser {
  __typename: "Cohort";
  id: string;
  internalLabel: string | null;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  archived: boolean;
  registrationCloseDate: any | null;
  registrationOpenDate: any | null;
  preRegistrantsCount: number | null;
  newRegistrantsCount: number | null;
  registeredCount: number | null;
  waitlistCount: number | null;
  deniedCount: number | null;
  nextSessionDate: string | null;
  program: GetAllOpenCohortsForUser_getAllOpenCohortsForUser_program | null;
  developmentUser: GetAllOpenCohortsForUser_getAllOpenCohortsForUser_developmentUser | null;
  implementationUser: GetAllOpenCohortsForUser_getAllOpenCohortsForUser_implementationUser | null;
  evaluationUser: GetAllOpenCohortsForUser_getAllOpenCohortsForUser_evaluationUser | null;
  developmentSupportUser: (GetAllOpenCohortsForUser_getAllOpenCohortsForUser_developmentSupportUser | null)[] | null;
  evaluationSupportUser: (GetAllOpenCohortsForUser_getAllOpenCohortsForUser_evaluationSupportUser | null)[] | null;
  implementationSupportUser: (GetAllOpenCohortsForUser_getAllOpenCohortsForUser_implementationSupportUser | null)[] | null;
}

export interface GetAllOpenCohortsForUser {
  getAllOpenCohortsForUser: GetAllOpenCohortsForUser_getAllOpenCohortsForUser[] | null;
}

export interface GetAllOpenCohortsForUserVariables {
  userId: string;
  status: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortByHubMember
// ====================================================

export interface GetCohortByHubMember_getCohortByHubMember {
  __typename: "Cohort";
  id: string;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  nextSessionDate: string | null;
}

export interface GetCohortByHubMember {
  getCohortByHubMember: GetCohortByHubMember_getCohortByHubMember[] | null;
}

export interface GetCohortByHubMemberVariables {
  userid: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortById
// ====================================================

export interface GetCohortById_getCohortById_registrationQuestions_yesNo {
  __typename: "RegistrationQuestionYesNo";
  noText: string | null;
  yesText: string | null;
}

export interface GetCohortById_getCohortById_registrationQuestions_options {
  __typename: "RegistrationQuestionOption";
  label: string | null;
  value: string | null;
}

export interface GetCohortById_getCohortById_registrationQuestions_scale {
  __typename: "RegistrationQuestionScale";
  label: string | null;
}

export interface GetCohortById_getCohortById_registrationQuestions_statements {
  __typename: "RegistrationQuestionStatements";
  name: string | null;
  statement: string | null;
}

export interface GetCohortById_getCohortById_registrationQuestions {
  __typename: "RegistrationQuestion";
  id: string;
  title: string;
  type: string;
  name: string;
  required: boolean;
  isMultiSelect: boolean | null;
  yesNo: GetCohortById_getCohortById_registrationQuestions_yesNo | null;
  options: GetCohortById_getCohortById_registrationQuestions_options[] | null;
  hasOtherOption: boolean | null;
  scale: GetCohortById_getCohortById_registrationQuestions_scale[] | null;
  statements: GetCohortById_getCohortById_registrationQuestions_statements[] | null;
}

export interface GetCohortById_getCohortById {
  __typename: "Cohort";
  id: string;
  programId: string;
  developmentUserId: string | null;
  implementationUserId: string | null;
  evaluationUserId: string | null;
  meetingLink: string | null;
  caseFormLink: string | null;
  evaluationGoal: string | null;
  audience: string | null;
  agreement: string | null;
  startDate: any | null;
  endDate: any | null;
  scheduleDescription: string | null;
  statistics: string | null;
  registrationOpenDate: any | null;
  registrationCloseDate: any | null;
  registrationQuestions: GetCohortById_getCohortById_registrationQuestions[] | null;
  archived: boolean;
  internalLabel: string | null;
  externalLabel: string | null;
  prelaunchDateTime: any | null;
  projectPartner: string | null;
  tenantId: string;
  learningObjectives: string | null;
  surveyLink: string | null;
  timeZone: string | null;
  allowPreregistration: boolean | null;
  isOngoing: boolean;
  sessionDurationInMinutes: number;
  developmentSupportUserIds: string[] | null;
  evaluationSupportUserIds: string[] | null;
  implementationSupportUserIds: string[] | null;
}

export interface GetCohortById {
  getCohortById: GetCohortById_getCohortById;
}

export interface GetCohortByIdVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortDashboard
// ====================================================

export interface GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubMemberCohort_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubMemberCohort {
  __typename: "HubMemberCohort";
  id: string;
  facilitateAll: boolean | null;
  presentAll: boolean | null;
  panelistAll: boolean | null;
  credentials: string | null;
  hide: boolean | null;
  user: GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubMemberCohort_user | null;
}

export interface GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubRole {
  __typename: "HubRole";
  id: string;
  name: string;
}

export interface GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession {
  __typename: "HubMemberCohortSession";
  id: string;
  hubRoleId: string;
  hubMemberCohort: GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubMemberCohort | null;
  hubRole: GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession_hubRole;
}

export interface GetCohortDashboard_getCohortDashboard_allSessions {
  __typename: "Session";
  id: string;
  title: string | null;
  number: number | null;
  startDate: any | null;
  endDate: any | null;
  HubMemberCohortSession: (GetCohortDashboard_getCohortDashboard_allSessions_HubMemberCohortSession | null)[] | null;
}

export interface GetCohortDashboard_getCohortDashboard_registrationQuestions_yesNo {
  __typename: "RegistrationQuestionYesNo";
  noText: string | null;
  yesText: string | null;
}

export interface GetCohortDashboard_getCohortDashboard_registrationQuestions_options {
  __typename: "RegistrationQuestionOption";
  label: string | null;
  value: string | null;
}

export interface GetCohortDashboard_getCohortDashboard_registrationQuestions_scale {
  __typename: "RegistrationQuestionScale";
  label: string | null;
}

export interface GetCohortDashboard_getCohortDashboard_registrationQuestions_statements {
  __typename: "RegistrationQuestionStatements";
  name: string | null;
  statement: string | null;
}

export interface GetCohortDashboard_getCohortDashboard_registrationQuestions {
  __typename: "RegistrationQuestion";
  id: string;
  title: string;
  type: string;
  name: string;
  required: boolean;
  isMultiSelect: boolean | null;
  yesNo: GetCohortDashboard_getCohortDashboard_registrationQuestions_yesNo | null;
  options: GetCohortDashboard_getCohortDashboard_registrationQuestions_options[] | null;
  hasOtherOption: boolean | null;
  scale: GetCohortDashboard_getCohortDashboard_registrationQuestions_scale[] | null;
  statements: GetCohortDashboard_getCohortDashboard_registrationQuestions_statements[] | null;
}

export interface GetCohortDashboard_getCohortDashboard_sessions_sessionResources_resource {
  __typename: "Resource";
  name: string;
  displayName: string | null;
  url: string | null;
  archived: boolean;
  deleted: boolean;
}

export interface GetCohortDashboard_getCohortDashboard_sessions_sessionResources {
  __typename: "SessionResource";
  id: string;
  resource: GetCohortDashboard_getCohortDashboard_sessions_sessionResources_resource;
}

export interface GetCohortDashboard_getCohortDashboard_sessions {
  __typename: "Session";
  id: string;
  title: string | null;
  number: number | null;
  startDate: any | null;
  endDate: any | null;
  sessionResources: GetCohortDashboard_getCohortDashboard_sessions_sessionResources[] | null;
}

export interface GetCohortDashboard_getCohortDashboard_developmentUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard_implementationUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard_evaluationUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard_developmentSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard_evaluationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard_implementationSupportUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetCohortDashboard_getCohortDashboard {
  __typename: "CohortDashboard";
  allSessions: GetCohortDashboard_getCohortDashboard_allSessions[] | null;
  allowPreregistration: boolean | null;
  internalTitle: string | null;
  externalTitle: string | null;
  evaluationGoal: string | null;
  learningObjectives: string | null;
  audience: string | null;
  agreement: string | null;
  partnerName: string | null;
  registrationOpenDate: any | null;
  registrationCloseDate: any | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean | null;
  meetingLink: string | null;
  caseFormLink: string | null;
  surveyLink: string | null;
  scheduleDescription: string | null;
  registrationQuestions: GetCohortDashboard_getCohortDashboard_registrationQuestions[] | null;
  sessions: GetCohortDashboard_getCohortDashboard_sessions[] | null;
  preRegistrantCount: number;
  newRegistrantCount: number;
  registeredCount: number;
  waitlistedCount: number;
  deniedCount: number;
  developmentUser: GetCohortDashboard_getCohortDashboard_developmentUser | null;
  implementationUser: GetCohortDashboard_getCohortDashboard_implementationUser | null;
  evaluationUser: GetCohortDashboard_getCohortDashboard_evaluationUser | null;
  developmentSupportUser: (GetCohortDashboard_getCohortDashboard_developmentSupportUser | null)[] | null;
  evaluationSupportUser: (GetCohortDashboard_getCohortDashboard_evaluationSupportUser | null)[] | null;
  implementationSupportUser: (GetCohortDashboard_getCohortDashboard_implementationSupportUser | null)[] | null;
}

export interface GetCohortDashboard {
  getCohortDashboard: GetCohortDashboard_getCohortDashboard;
}

export interface GetCohortDashboardVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortDetailForLearner
// ====================================================

export interface GetCohortDetailForLearner_getCohortDetailForLearner_cohortResources {
  __typename: "Resource";
  displayName: string | null;
  id: string;
  name: string;
  resourceTypeId: string | null;
  url: string | null;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources_resource {
  __typename: "Resource";
  displayName: string | null;
  id: string;
  name: string;
  resourceTypeId: string | null;
  url: string | null;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources {
  __typename: "CohortResource";
  sessionId: string | null;
  resource: GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources_resource;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubMemberCohort_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubMemberCohort {
  __typename: "HubMemberCohort";
  id: string;
  facilitateAll: boolean | null;
  presentAll: boolean | null;
  panelistAll: boolean | null;
  credentials: string | null;
  hide: boolean | null;
  user: GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubMemberCohort_user | null;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubRole {
  __typename: "HubRole";
  id: string;
  name: string;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession {
  __typename: "HubMemberCohortSession";
  id: string;
  hubRoleId: string;
  hubMemberCohort: GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubMemberCohort | null;
  hubRole: GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession_hubRole;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner_sessions {
  __typename: "Session";
  id: string;
  number: number | null;
  title: string | null;
  startDate: any | null;
  endDate: any | null;
  HubMemberCohortSession: (GetCohortDetailForLearner_getCohortDetailForLearner_sessions_HubMemberCohortSession | null)[] | null;
}

export interface GetCohortDetailForLearner_getCohortDetailForLearner {
  __typename: "CohortDetailForLearner";
  cohortResources: GetCohortDetailForLearner_getCohortDetailForLearner_cohortResources[] | null;
  sessionResources: GetCohortDetailForLearner_getCohortDetailForLearner_sessionResources[] | null;
  id: string;
  endDate: any | null;
  evaluationGoal: string | null;
  externalLabel: string | null;
  internalLabel: string | null;
  isOngoing: boolean | null;
  learningObjectives: string | null;
  meetingLink: string | null;
  programId: string;
  projectPartner: string | null;
  scheduleDescription: string | null;
  startDate: any | null;
  sessions: GetCohortDetailForLearner_getCohortDetailForLearner_sessions[] | null;
}

export interface GetCohortDetailForLearner {
  getCohortDetailForLearner: GetCohortDetailForLearner_getCohortDetailForLearner;
}

export interface GetCohortDetailForLearnerVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortRoster
// ====================================================

export interface GetCohortRoster_getCohortRoster_cohortRosters {
  __typename: "CohortRoster";
  name: string;
  email: string;
  credentials: string | null;
  organization: string | null;
  location: string | null;
}

export interface GetCohortRoster_getCohortRoster {
  __typename: "CohortRosterDisplay";
  cohortTitle: string;
  cohortRosters: GetCohortRoster_getCohortRoster_cohortRosters[] | null;
}

export interface GetCohortRoster {
  getCohortRoster: GetCohortRoster_getCohortRoster | null;
}

export interface GetCohortRosterVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortsForProgram
// ====================================================

export interface GetCohortsForProgram_getCohortsForProgram {
  __typename: "Cohort";
  id: string;
  startDate: any | null;
  endDate: any | null;
  archived: boolean;
  internalLabel: string | null;
  registrationCloseDate: any | null;
  isOngoing: boolean;
}

export interface GetCohortsForProgram {
  getCohortsForProgram: GetCohortsForProgram_getCohortsForProgram[] | null;
}

export interface GetCohortsForProgramVariables {
  programId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCohortsOpenForRegistration
// ====================================================

export interface GetCohortsOpenForRegistration_getCohortsOpenForRegistration {
  __typename: "Cohort";
  id: string;
  internalLabel: string | null;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  allowPreregistration: boolean | null;
}

export interface GetCohortsOpenForRegistration {
  getCohortsOpenForRegistration: GetCohortsOpenForRegistration_getCohortsOpenForRegistration[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLearnerCohorts
// ====================================================

export interface GetLearnerCohorts_getLearnerCohorts_currentCohorts {
  __typename: "Cohort";
  id: string;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  nextSessionDate: string | null;
}

export interface GetLearnerCohorts_getLearnerCohorts_pendingCohorts {
  __typename: "Cohort";
  id: string;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  nextSessionDate: string | null;
}

export interface GetLearnerCohorts_getLearnerCohorts_waitlistCohorts {
  __typename: "Cohort";
  id: string;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  nextSessionDate: string | null;
}

export interface GetLearnerCohorts_getLearnerCohorts_completedCohorts {
  __typename: "Cohort";
  id: string;
  externalLabel: string | null;
  startDate: any | null;
  endDate: any | null;
  isOngoing: boolean;
  meetingLink: string | null;
  nextSessionDate: string | null;
}

export interface GetLearnerCohorts_getLearnerCohorts {
  __typename: "LearnerRegistrations";
  currentCohorts: GetLearnerCohorts_getLearnerCohorts_currentCohorts[] | null;
  pendingCohorts: GetLearnerCohorts_getLearnerCohorts_pendingCohorts[] | null;
  waitlistCohorts: GetLearnerCohorts_getLearnerCohorts_waitlistCohorts[] | null;
  completedCohorts: GetLearnerCohorts_getLearnerCohorts_completedCohorts[] | null;
}

export interface GetLearnerCohorts {
  getLearnerCohorts: GetLearnerCohorts_getLearnerCohorts | null;
}

export interface GetLearnerCohortsVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCohortEmail
// ====================================================

export interface SendCohortEmail_sendCohortEmail {
  __typename: "CohortEmailResult";
  success: boolean;
  numberSent: number;
  error: string | null;
}

export interface SendCohortEmail {
  sendCohortEmail: SendCohortEmail_sendCohortEmail;
}

export interface SendCohortEmailVariables {
  cohortEmail: SendCohortEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateAllRegistrationQuestions
// ====================================================

export interface updateAllRegistrationQuestions_updateAllRegistrationQuestions {
  __typename: "Cohort";
  id: string;
}

export interface updateAllRegistrationQuestions {
  updateAllRegistrationQuestions: updateAllRegistrationQuestions_updateAllRegistrationQuestions;
}

export interface updateAllRegistrationQuestionsVariables {
  cohortId: string;
  registrationQuestions: RegistrationQuestionInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCohort
// ====================================================

export interface EditCohort_editCohort {
  __typename: "Cohort";
  id: string;
  internalLabel: string | null;
}

export interface EditCohort {
  editCohort: EditCohort_editCohort;
}

export interface EditCohortVariables {
  cohort: EditCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRegistrationQuestion
// ====================================================

export interface UpdateRegistrationQuestion_updateRegistrationQuestion {
  __typename: "Cohort";
  id: string;
}

export interface UpdateRegistrationQuestion {
  updateRegistrationQuestion: UpdateRegistrationQuestion_updateRegistrationQuestion;
}

export interface UpdateRegistrationQuestionVariables {
  cohortId: string;
  registrationQuestion: RegistrationQuestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrUpdateCohortAttendance
// ====================================================

export interface AddOrUpdateCohortAttendance_addOrUpdateCohortAttendance {
  __typename: "CohortAttendance";
  id: string;
}

export interface AddOrUpdateCohortAttendance {
  addOrUpdateCohortAttendance: AddOrUpdateCohortAttendance_addOrUpdateCohortAttendance;
}

export interface AddOrUpdateCohortAttendanceVariables {
  cohortAttendance: AddOrUpdateCohortAttendanceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAttendanceForSession
// ====================================================

export interface GetAttendanceForSession_getAttendanceForSession {
  __typename: "CohortRegistrantsWithAttendance";
  cohortRegistrationId: string;
  sessionId: string;
  userId: string;
  sessionAttendanceId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  credentials: string | null;
  organization: string | null;
  isPresent: boolean | null;
  notes: string | null;
}

export interface GetAttendanceForSession {
  getAttendanceForSession: GetAttendanceForSession_getAttendanceForSession[] | null;
}

export interface GetAttendanceForSessionVariables {
  cohortId: string;
  sessionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPreRegistrant
// ====================================================

export interface AddPreRegistrant_addPreRegistrant {
  __typename: "CohortPreRegistration";
  id: string;
}

export interface AddPreRegistrant {
  addPreRegistrant: AddPreRegistrant_addPreRegistrant;
}

export interface AddPreRegistrantVariables {
  preRegistrant: AddCohortPreRegistrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreRegistrantsForCohort
// ====================================================

export interface GetPreRegistrantsForCohort_getPreRegistrantsForCohort_user_profession {
  __typename: "Profession";
  name: string;
}

export interface GetPreRegistrantsForCohort_getPreRegistrantsForCohort_user {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
  email: string;
  organization: string | null;
  profession: GetPreRegistrantsForCohort_getPreRegistrantsForCohort_user_profession | null;
}

export interface GetPreRegistrantsForCohort_getPreRegistrantsForCohort {
  __typename: "CohortPreRegistration";
  id: string;
  cohortId: string;
  userId: string | null;
  email: string;
  emailSent: boolean | null;
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  profession: string | null;
  createdAt: any | null;
  user: GetPreRegistrantsForCohort_getPreRegistrantsForCohort_user | null;
}

export interface GetPreRegistrantsForCohort {
  getPreRegistrantsForCohort: GetPreRegistrantsForCohort_getPreRegistrantsForCohort[] | null;
}

export interface GetPreRegistrantsForCohortVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPreRegistrationsForUser
// ====================================================

export interface GetPreRegistrationsForUser_getPreRegistrationsForUser {
  __typename: "CohortPreRegistration";
  id: string;
  cohortId: string;
  userId: string | null;
  email: string;
  emailSent: boolean | null;
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  profession: string | null;
  createdAt: any | null;
}

export interface GetPreRegistrationsForUser {
  getPreRegistrationsForUser: GetPreRegistrationsForUser_getPreRegistrationsForUser[] | null;
}

export interface GetPreRegistrationsForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendInvitationToPreRegistrant
// ====================================================

export interface SendInvitationToPreRegistrant {
  sendInvitationToPreRegistrant: boolean;
}

export interface SendInvitationToPreRegistrantVariables {
  preRegistrantId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendInvitationToAllPreRegistrants
// ====================================================

export interface SendInvitationToAllPreRegistrants {
  sendInvitationToAllPreRegistrants: boolean;
}

export interface SendInvitationToAllPreRegistrantsVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCohortRegistration
// ====================================================

export interface AddCohortRegistration_addCohortRegistration {
  __typename: "CohortRegistration";
  id: string;
}

export interface AddCohortRegistration {
  addCohortRegistration: AddCohortRegistration_addCohortRegistration;
}

export interface AddCohortRegistrationVariables {
  cohortRegistration: AddCohortRegistrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApproveCohortRegistration
// ====================================================

export interface ApproveCohortRegistration_approveCohortRegistration {
  __typename: "CohortRegistration";
  id: string;
  updatedAt: any | null;
}

export interface ApproveCohortRegistration {
  approveCohortRegistration: ApproveCohortRegistration_approveCohortRegistration;
}

export interface ApproveCohortRegistrationVariables {
  cohortRegistration: ApproveCohortRegistrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCohortRegistration
// ====================================================

export interface DeleteCohortRegistration_deleteCohortRegistration {
  __typename: "DeletedRegistration";
  message: string;
  status: string;
}

export interface DeleteCohortRegistration {
  deleteCohortRegistration: DeleteCohortRegistration_deleteCohortRegistration;
}

export interface DeleteCohortRegistrationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegistrationDenialEmail
// ====================================================

export interface GetRegistrationDenialEmail_getRegistrationDenialEmail {
  __typename: "RegistrationDenialEmail";
  subject: string;
  text: string;
}

export interface GetRegistrationDenialEmail {
  getRegistrationDenialEmail: GetRegistrationDenialEmail_getRegistrationDenialEmail;
}

export interface GetRegistrationDenialEmailVariables {
  cohortRegistrationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DenyCohortRegistration
// ====================================================

export interface DenyCohortRegistration_denyCohortRegistration {
  __typename: "CohortRegistration";
  id: string;
  updatedAt: any | null;
}

export interface DenyCohortRegistration {
  denyCohortRegistration: DenyCohortRegistration_denyCohortRegistration;
}

export interface DenyCohortRegistrationVariables {
  cohortRegistration: DenyCohortRegistrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegistrantsForCohort
// ====================================================

export interface GetRegistrantsForCohort_getRegistrantsForCohort_user_profession {
  __typename: "Profession";
  name: string;
}

export interface GetRegistrantsForCohort_getRegistrantsForCohort_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  organization: string | null;
  userCredentialsForDisplay: string | null;
  profession: GetRegistrantsForCohort_getRegistrantsForCohort_user_profession | null;
}

export interface GetRegistrantsForCohort_getRegistrantsForCohort {
  __typename: "CohortRegistration";
  id: string;
  userId: string;
  isActive: boolean;
  isApproved: boolean | null;
  isDenied: boolean | null;
  isWaitlisted: boolean | null;
  copiedFromWaitlist: boolean;
  createdAt: any | null;
  registrationAnswers: string | null;
  user: GetRegistrantsForCohort_getRegistrantsForCohort_user;
}

export interface GetRegistrantsForCohort {
  getRegistrantsForCohort: GetRegistrantsForCohort_getRegistrantsForCohort[] | null;
}

export interface GetRegistrantsForCohortVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRegistrationsForUser
// ====================================================

export interface GetRegistrationsForUser_getRegistrationsForUser {
  __typename: "CohortRegistration";
  id: string;
  cohortId: string;
  userId: string;
  isActive: boolean;
  isApproved: boolean | null;
  isDenied: boolean | null;
  isWaitlisted: boolean | null;
  copiedFromWaitlist: boolean;
  createdAt: any | null;
}

export interface GetRegistrationsForUser {
  getRegistrationsForUser: GetRegistrationsForUser_getRegistrationsForUser[] | null;
}

export interface GetRegistrationsForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendRegistrationConfirmationEmail
// ====================================================

export interface ResendRegistrationConfirmationEmail_resendRegistrationConfirmationEmail {
  __typename: "CohortRegistration";
  id: string;
  updatedAt: any | null;
}

export interface ResendRegistrationConfirmationEmail {
  resendRegistrationConfirmationEmail: ResendRegistrationConfirmationEmail_resendRegistrationConfirmationEmail;
}

export interface ResendRegistrationConfirmationEmailVariables {
  cohortRegistrationId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleCohortRegistrationActive
// ====================================================

export interface ToggleCohortRegistrationActive_toggleCohortRegistrationActive {
  __typename: "CohortRegistration";
  id: string;
  updatedAt: any | null;
}

export interface ToggleCohortRegistrationActive {
  toggleCohortRegistrationActive: ToggleCohortRegistrationActive_toggleCohortRegistrationActive;
}

export interface ToggleCohortRegistrationActiveVariables {
  cohortRegistration: ToggleCohortRegistrationActiveInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WaitlistCohortRegistration
// ====================================================

export interface WaitlistCohortRegistration_waitlistCohortRegistration {
  __typename: "CohortRegistration";
  id: string;
  updatedAt: any | null;
}

export interface WaitlistCohortRegistration {
  waitlistCohortRegistration: WaitlistCohortRegistration_waitlistCohortRegistration;
}

export interface WaitlistCohortRegistrationVariables {
  cohortRegistration: WaitlistCohortRegistrationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteEmailTemplate
// ====================================================

export interface DeleteEmailTemplate_deleteEmailTemplate {
  __typename: "EmailTemplate";
  id: string;
}

export interface DeleteEmailTemplate {
  deleteEmailTemplate: DeleteEmailTemplate_deleteEmailTemplate;
}

export interface DeleteEmailTemplateVariables {
  emailTemplateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailTemplatesForTenant
// ====================================================

export interface GetEmailTemplatesForTenant_getEmailTemplatesForTenant {
  __typename: "EmailTemplate";
  emailCategory: EmailCategory | null;
  id: string;
  name: string;
  subject: string;
  template: string;
}

export interface GetEmailTemplatesForTenant {
  getEmailTemplatesForTenant: GetEmailTemplatesForTenant_getEmailTemplatesForTenant[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendEmail
// ====================================================

export interface SendEmail_sendEmail {
  __typename: "EmailDeliverySuccess";
  success: boolean | null;
}

export interface SendEmail {
  sendEmail: SendEmail_sendEmail | null;
}

export interface SendEmailVariables {
  email: SendEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmailTemplate
// ====================================================

export interface UpdateEmailTemplate_updateEmailTemplate {
  __typename: "EmailTemplate";
  id: string;
}

export interface UpdateEmailTemplate {
  updateEmailTemplate: UpdateEmailTemplate_updateEmailTemplate;
}

export interface UpdateEmailTemplateVariables {
  template: UpdateEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddExistingHubMemberCohort
// ====================================================

export interface AddExistingHubMemberCohort_addExistingHubMemberCohort {
  __typename: "HubMemberCohort";
  id: string;
  userId: string;
  cohortId: string;
}

export interface AddExistingHubMemberCohort {
  addExistingHubMemberCohort: AddExistingHubMemberCohort_addExistingHubMemberCohort | null;
}

export interface AddExistingHubMemberCohortVariables {
  hubMemberCohort: AddExistingHubMemberCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddNewHubMemberCohort
// ====================================================

export interface AddNewHubMemberCohort_addNewHubMemberCohort {
  __typename: "HubMemberCohort";
  id: string;
  userId: string;
  cohortId: string;
}

export interface AddNewHubMemberCohort {
  addNewHubMemberCohort: AddNewHubMemberCohort_addNewHubMemberCohort | null;
}

export interface AddNewHubMemberCohortVariables {
  hubMemberCohort: AddNewHubMemberCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteHubMemberCohortById
// ====================================================

export interface DeleteHubMemberCohortById {
  deleteHubMemberCohortById: string;
}

export interface DeleteHubMemberCohortByIdVariables {
  hubMemberCohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMembersByCohort
// ====================================================

export interface GetMembersByCohort_getMembersByCohort_user {
  __typename: "User";
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface GetMembersByCohort_getMembersByCohort {
  __typename: "HubMemberCohort";
  id: string;
  userId: string;
  cohortId: string;
  nextStepsSentDate: any | null;
  user: GetMembersByCohort_getMembersByCohort_user | null;
  facilitateAll: boolean | null;
  presentAll: boolean | null;
  panelistAll: boolean | null;
  sessionsAsFacilitator: string[];
  sessionsAsPresenter: string[];
  sessionsAsPanelist: string[];
  credentials: string | null;
  hide: boolean | null;
}

export interface GetMembersByCohort {
  getMembersByCohort: GetMembersByCohort_getMembersByCohort[] | null;
}

export interface GetMembersByCohortVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNextStepsEmailContent
// ====================================================

export interface GetNextStepsEmailContent_getNextStepsEmailContent {
  __typename: "EmailContent";
  subject: string;
  text: string;
}

export interface GetNextStepsEmailContent {
  getNextStepsEmailContent: GetNextStepsEmailContent_getNextStepsEmailContent;
}

export interface GetNextStepsEmailContentVariables {
  hubMemberCohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendNextStepsEmail
// ====================================================

export interface SendNextStepsEmail {
  sendNextStepsEmail: boolean | null;
}

export interface SendNextStepsEmailVariables {
  emailData: SendNextStepsEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateHubMemberCohortAndSessions
// ====================================================

export interface UpdateHubMemberCohortAndSessions_updateHubMemberCohortAndSessions {
  __typename: "HubMemberCohort";
  id: string;
  userId: string;
  cohortId: string;
}

export interface UpdateHubMemberCohortAndSessions {
  updateHubMemberCohortAndSessions: UpdateHubMemberCohortAndSessions_updateHubMemberCohortAndSessions | null;
}

export interface UpdateHubMemberCohortAndSessionsVariables {
  hubMemberCohort: UpdateHubMemberCohortInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddPartner
// ====================================================

export interface AddPartner_addPartner {
  __typename: "Partner";
  id: string;
  name: string;
}

export interface AddPartner {
  addPartner: AddPartner_addPartner;
}

export interface AddPartnerVariables {
  partner: AddPartnerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePartner
// ====================================================

export interface DeletePartner_deletePartner {
  __typename: "Partner";
  id: string;
}

export interface DeletePartner {
  deletePartner: DeletePartner_deletePartner;
}

export interface DeletePartnerVariables {
  partnerId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllPartners
// ====================================================

export interface GetAllPartners_getPartners {
  __typename: "Partner";
  id: string;
  name: string;
}

export interface GetAllPartners {
  getPartners: GetAllPartners_getPartners[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePartner
// ====================================================

export interface UpdatePartner_updatePartner {
  __typename: "Partner";
  id: string;
  name: string;
}

export interface UpdatePartner {
  updatePartner: UpdatePartner_updatePartner;
}

export interface UpdatePartnerVariables {
  partner: UpdatePartnerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddProgram
// ====================================================

export interface AddProgram_addProgram {
  __typename: "Program";
  id: string;
  name: string;
}

export interface AddProgram {
  addProgram: AddProgram_addProgram;
}

export interface AddProgramVariables {
  program: AddProgramInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllPrograms
// ====================================================

export interface GetAllPrograms_getAllPrograms_partner {
  __typename: "Partner";
  name: string;
}

export interface GetAllPrograms_getAllPrograms__count {
  __typename: "QueryCount";
  cohorts: number | null;
}

export interface GetAllPrograms_getAllPrograms {
  __typename: "Program";
  id: string;
  name: string;
  partnerId: string | null;
  partner: GetAllPrograms_getAllPrograms_partner | null;
  isArchived: boolean;
  _count: GetAllPrograms_getAllPrograms__count | null;
}

export interface GetAllPrograms {
  getAllPrograms: GetAllPrograms_getAllPrograms[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProgramById
// ====================================================

export interface GetProgramById_getProgramById_resources {
  __typename: "Resource";
  displayName: string | null;
  name: string;
  resourceTypeId: string | null;
  url: string | null;
  createdAt: any | null;
}

export interface GetProgramById_getProgramById_cohorts {
  __typename: "Cohort";
  id: string;
  startDate: any | null;
  endDate: any | null;
  archived: boolean;
  internalLabel: string | null;
  registrationCloseDate: any | null;
  isOngoing: boolean;
}

export interface GetProgramById_getProgramById {
  __typename: "Program";
  id: string;
  name: string;
  partnerId: string | null;
  createdAt: any | null;
  updatedAt: any | null;
  isArchived: boolean;
  resources: GetProgramById_getProgramById_resources[] | null;
  cohorts: GetProgramById_getProgramById_cohorts[] | null;
}

export interface GetProgramById {
  getProgramById: GetProgramById_getProgramById | null;
}

export interface GetProgramByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProgram
// ====================================================

export interface UpdateProgram_updateProgram {
  __typename: "Program";
  id: string;
  name: string;
  createdAt: any | null;
  updatedAt: any | null;
}

export interface UpdateProgram {
  updateProgram: UpdateProgram_updateProgram;
}

export interface UpdateProgramVariables {
  program: UpdateProgramInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyEmailTemplatesFromTenant
// ====================================================

export interface CopyEmailTemplatesFromTenant_copyEmailTemplatesFromTenant {
  __typename: "ProgramEmailTemplate";
  id: string;
  name: string;
}

export interface CopyEmailTemplatesFromTenant {
  copyEmailTemplatesFromTenant: CopyEmailTemplatesFromTenant_copyEmailTemplatesFromTenant[] | null;
}

export interface CopyEmailTemplatesFromTenantVariables {
  programId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailTemplatesForProgram
// ====================================================

export interface GetEmailTemplatesForProgram_getEmailTemplatesForProgram {
  __typename: "ProgramEmailTemplate";
  emailCategory: EmailCategory | null;
  id: string;
  name: string;
  subject: string;
  template: string;
}

export interface GetEmailTemplatesForProgram {
  getEmailTemplatesForProgram: GetEmailTemplatesForProgram_getEmailTemplatesForProgram[] | null;
}

export interface GetEmailTemplatesForProgramVariables {
  programId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProgramEmailTemplate
// ====================================================

export interface UpdateProgramEmailTemplate_updateProgramEmailTemplate {
  __typename: "ProgramEmailTemplate";
  id: string;
}

export interface UpdateProgramEmailTemplate {
  updateProgramEmailTemplate: UpdateProgramEmailTemplate_updateProgramEmailTemplate;
}

export interface UpdateProgramEmailTemplateVariables {
  template: UpdateProgramEmailTemplateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddResource
// ====================================================

export interface AddResource_addResource {
  __typename: "Resource";
  id: string;
}

export interface AddResource {
  addResource: AddResource_addResource;
}

export interface AddResourceVariables {
  resource: AddResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteResource
// ====================================================

export interface DeleteResource {
  deleteResource: boolean;
}

export interface DeleteResourceVariables {
  resourceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDataForResourceEdit
// ====================================================

export interface GetDataForResourceEdit_getDataForResourceEdit_resource {
  __typename: "Resource";
  id: string;
  name: string;
  displayName: string | null;
  resourceTypeId: string | null;
  url: string | null;
}

export interface GetDataForResourceEdit_getDataForResourceEdit_resourceTypes {
  __typename: "ResourceType";
  id: string;
  name: string;
}

export interface GetDataForResourceEdit_getDataForResourceEdit_cohorts_sessions {
  __typename: "Session";
  id: string;
  title: string | null;
  number: number | null;
}

export interface GetDataForResourceEdit_getDataForResourceEdit_cohorts {
  __typename: "CohortLookup";
  id: string;
  internalLabel: string | null;
  sessions: GetDataForResourceEdit_getDataForResourceEdit_cohorts_sessions[] | null;
}

export interface GetDataForResourceEdit_getDataForResourceEdit_cohortResources {
  __typename: "CohortResource";
  id: string;
  resourceId: string;
  cohortId: string;
  sessionId: string | null;
}

export interface GetDataForResourceEdit_getDataForResourceEdit {
  __typename: "DataForResourceEdit";
  resource: GetDataForResourceEdit_getDataForResourceEdit_resource;
  resourceTypes: GetDataForResourceEdit_getDataForResourceEdit_resourceTypes[];
  cohorts: GetDataForResourceEdit_getDataForResourceEdit_cohorts[] | null;
  cohortResources: GetDataForResourceEdit_getDataForResourceEdit_cohortResources[] | null;
}

export interface GetDataForResourceEdit {
  getDataForResourceEdit: GetDataForResourceEdit_getDataForResourceEdit;
}

export interface GetDataForResourceEditVariables {
  resourceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetResourceByProgram
// ====================================================

export interface GetResourceByProgram_getResourceByProgram_resourceType {
  __typename: "ResourceType";
  name: string;
}

export interface GetResourceByProgram_getResourceByProgram {
  __typename: "Resource";
  id: string;
  programId: string;
  resourceTypeId: string | null;
  name: string;
  displayName: string | null;
  url: string | null;
  archived: boolean;
  createdAt: any | null;
  resourceType: GetResourceByProgram_getResourceByProgram_resourceType | null;
}

export interface GetResourceByProgram {
  getResourceByProgram: GetResourceByProgram_getResourceByProgram[] | null;
}

export interface GetResourceByProgramVariables {
  programId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleArchiveState
// ====================================================

export interface ToggleArchiveState_toggleArchiveState {
  __typename: "Resource";
  id: string;
  archived: boolean;
}

export interface ToggleArchiveState {
  toggleArchiveState: ToggleArchiveState_toggleArchiveState;
}

export interface ToggleArchiveStateVariables {
  resourceId: string;
  isArchived: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateResource
// ====================================================

export interface UpdateResource_updateResource {
  __typename: "Resource";
  id: string;
}

export interface UpdateResource {
  updateResource: UpdateResource_updateResource;
}

export interface UpdateResourceVariables {
  resource: UpdateResourceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSession
// ====================================================

export interface AddSession_addSession {
  __typename: "Session";
  id: string;
}

export interface AddSession {
  addSession: AddSession_addSession;
}

export interface AddSessionVariables {
  session: AddSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSessionsByCohort
// ====================================================

export interface GetSessionsByCohort_getSessionsByCohort {
  __typename: "Session";
  id: string;
  cohortId: string;
  number: number | null;
  title: string | null;
  startDate: any | null;
  endDate: any | null;
  active: boolean;
  learningOutcomes: string | null;
  presenterNotes: string | null;
  facultyNotes: string | null;
  isFuture: boolean | null;
}

export interface GetSessionsByCohort {
  getSessionsByCohort: GetSessionsByCohort_getSessionsByCohort[] | null;
}

export interface GetSessionsByCohortVariables {
  cohortId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSession
// ====================================================

export interface UpdateSession_updateSession {
  __typename: "Session";
  id: string;
}

export interface UpdateSession {
  updateSession: UpdateSession_updateSession;
}

export interface UpdateSessionVariables {
  session: UpdateSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddStaffUser
// ====================================================

export interface AddStaffUser_addStaffUser {
  __typename: "StaffUser";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AddStaffUser {
  addStaffUser: AddStaffUser_addStaffUser | null;
}

export interface AddStaffUserVariables {
  user: AddOrUpdateStaffUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddUser
// ====================================================

export interface AddUser_addUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface AddUser {
  addUser: AddUser_addUser | null;
}

export interface AddUserVariables {
  user?: AddOrUpdateUserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccountLookups
// ====================================================

export interface GetAccountLookups_getAccountLookups_credentials {
  __typename: "Credential";
  id: string;
  name: string;
  isOther: boolean;
}

export interface GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions {
  __typename: "ThirdLevelProfession";
  id: string;
  name: string;
  isOther: boolean;
}

export interface GetAccountLookups_getAccountLookups_professions_secondLevelProfessions {
  __typename: "SecondLevelProfession";
  id: string;
  name: string;
  isOther: boolean;
  thirdLevelProfessions: GetAccountLookups_getAccountLookups_professions_secondLevelProfessions_thirdLevelProfessions[] | null;
}

export interface GetAccountLookups_getAccountLookups_professions {
  __typename: "Profession";
  id: string;
  name: string;
  isOther: boolean;
  secondLevelProfessions: GetAccountLookups_getAccountLookups_professions_secondLevelProfessions[] | null;
}

export interface GetAccountLookups_getAccountLookups_ethnicities {
  __typename: "Ethnicity";
  id: string;
  name: string;
  isOther: boolean;
}

export interface GetAccountLookups_getAccountLookups_counties {
  __typename: "County";
  id: string;
  name: string;
}

export interface GetAccountLookups_getAccountLookups_organizationDescriptions {
  __typename: "OrganizationDescription";
  id: string;
  name: string;
}

export interface GetAccountLookups_getAccountLookups_sexs {
  __typename: "Sex";
  id: string;
  name: string;
  sortOrder: number | null;
}

export interface GetAccountLookups_getAccountLookups_marketingSources {
  __typename: "MarketingSource";
  id: string;
  name: string;
  sortOrder: number | null;
}

export interface GetAccountLookups_getAccountLookups_roleDescriptions {
  __typename: "RoleDescription";
  id: string;
  name: string;
}

export interface GetAccountLookups_getAccountLookups_workSettings {
  __typename: "WorkSetting";
  id: string;
  name: string;
  isOther: boolean;
}

export interface GetAccountLookups_getAccountLookups {
  __typename: "AccountLookups";
  credentials: GetAccountLookups_getAccountLookups_credentials[] | null;
  professions: GetAccountLookups_getAccountLookups_professions[] | null;
  ethnicities: GetAccountLookups_getAccountLookups_ethnicities[] | null;
  counties: GetAccountLookups_getAccountLookups_counties[] | null;
  organizationDescriptions: GetAccountLookups_getAccountLookups_organizationDescriptions[] | null;
  sexs: GetAccountLookups_getAccountLookups_sexs[] | null;
  marketingSources: GetAccountLookups_getAccountLookups_marketingSources[] | null;
  roleDescriptions: GetAccountLookups_getAccountLookups_roleDescriptions[] | null;
  workSettings: GetAccountLookups_getAccountLookups_workSettings[] | null;
}

export interface GetAccountLookups {
  getAccountLookups: GetAccountLookups_getAccountLookups | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetEmailHistoryForUser
// ====================================================

export interface GetEmailHistoryForUser_getEmailHistoryForUser {
  __typename: "EmailHistory";
  fromAddress: string | null;
  subject: string | null;
  text: string | null;
  sentAt: any | null;
}

export interface GetEmailHistoryForUser {
  getEmailHistoryForUser: GetEmailHistoryForUser_getEmailHistoryForUser[] | null;
}

export interface GetEmailHistoryForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFullUserById
// ====================================================

export interface GetFullUserById_getFullUserById_cohortRegistrationsAsUsers_cohort_program {
  __typename: "Program";
  name: string;
}

export interface GetFullUserById_getFullUserById_cohortRegistrationsAsUsers_cohort {
  __typename: "Cohort";
  startDate: any | null;
  program: GetFullUserById_getFullUserById_cohortRegistrationsAsUsers_cohort_program | null;
}

export interface GetFullUserById_getFullUserById_cohortRegistrationsAsUsers {
  __typename: "CohortRegistration";
  createdAt: any | null;
  cohort: GetFullUserById_getFullUserById_cohortRegistrationsAsUsers_cohort | null;
}

export interface GetFullUserById_getFullUserById_emails {
  __typename: "Email";
  id: string;
  createdAt: any | null;
}

export interface GetFullUserById_getFullUserById {
  __typename: "User";
  id: string;
  fullName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string;
  otherCredential: string | null;
  professionId: string | null;
  secondLevelProfessionId: string | null;
  thirdLevelProfessionId: string | null;
  otherProfession: string | null;
  otherEthnicity: string | null;
  organization: string | null;
  organizationAddress1: string | null;
  organizationAddress2: string | null;
  city: string | null;
  state: string | null;
  countyId: string | null;
  zip: string | null;
  provideDirectCare: boolean | null;
  provideMedicaid: boolean | null;
  primaryCare: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
  credentials: string[] | null;
  receiveEmails: boolean | null;
  userCredentialIds: string[] | null;
  userEthnicityIds: string[] | null;
  partnerId: string | null;
  userCredentialsForDisplay: string | null;
  userProfessionsForDisplay: string | null;
  userEthnicitiesForDisplay: string | null;
  cohortRegistrationsAsUsers: GetFullUserById_getFullUserById_cohortRegistrationsAsUsers[] | null;
  emails: GetFullUserById_getFullUserById_emails[] | null;
}

export interface GetFullUserById {
  getFullUserById: GetFullUserById_getFullUserById | null;
}

export interface GetFullUserByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetHubTeamUsers
// ====================================================

export interface GetHubTeamUsers_getHubTeamUsers_developmentUsers {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetHubTeamUsers_getHubTeamUsers_implementationUsers {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetHubTeamUsers_getHubTeamUsers_evaluationUsers {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
}

export interface GetHubTeamUsers_getHubTeamUsers {
  __typename: "HubTeamUsers";
  developmentUsers: GetHubTeamUsers_getHubTeamUsers_developmentUsers[] | null;
  implementationUsers: GetHubTeamUsers_getHubTeamUsers_implementationUsers[] | null;
  evaluationUsers: GetHubTeamUsers_getHubTeamUsers_evaluationUsers[] | null;
}

export interface GetHubTeamUsers {
  getHubTeamUsers: GetHubTeamUsers_getHubTeamUsers | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMyAccount
// ====================================================

export interface GetMyAccount_getMyAccount {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  otherCredential: string | null;
  professionId: string | null;
  secondLevelProfessionId: string | null;
  thirdLevelProfessionId: string | null;
  otherProfession: string | null;
  otherWorkSetting: string | null;
  otherEthnicity: string | null;
  otherMarketingSource: string | null;
  otherSex: string | null;
  organization: string | null;
  organizationAddress1: string | null;
  organizationAddress2: string | null;
  city: string | null;
  state: string | null;
  countyId: string | null;
  zip: string | null;
  provideDirectCare: boolean | null;
  provideMedicaid: boolean | null;
  primaryCare: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
  credentials: string[] | null;
  receiveEmails: boolean | null;
  userCredentialIds: string[] | null;
  userEthnicityIds: string[] | null;
  partnerId: string | null;
  phone: string | null;
  birthYear: number | null;
  professionStartYear: number | null;
  firstSeries: boolean | null;
  organizationDescriptionIds: string[] | null;
  workSettingIds: string[] | null;
  userTenantIds: string[] | null;
  sexId: string | null;
  marketingSourceIds: string[] | null;
  roleDescriptionId: string | null;
  isRegistrationComplete: boolean;
}

export interface GetMyAccount {
  getMyAccount: GetMyAccount_getMyAccount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRolesForUser
// ====================================================

export interface GetRolesForUser_getRolesForUser {
  __typename: "UserRole";
  id: string;
  name: string;
}

export interface GetRolesForUser {
  getRolesForUser: GetRolesForUser_getRolesForUser[] | null;
}

export interface GetRolesForUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserById
// ====================================================

export interface GetUserById_getUserById {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  otherCredential: string | null;
  otherWorkSetting: string | null;
  professionId: string | null;
  secondLevelProfessionId: string | null;
  thirdLevelProfessionId: string | null;
  otherProfession: string | null;
  otherEthnicity: string | null;
  otherMarketingSource: string | null;
  organization: string | null;
  organizationAddress1: string | null;
  organizationAddress2: string | null;
  city: string | null;
  state: string | null;
  countyId: string | null;
  zip: string | null;
  provideDirectCare: boolean | null;
  provideMedicaid: boolean | null;
  primaryCare: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
  credentials: string[] | null;
  receiveEmails: boolean | null;
  userCredentialIds: string[] | null;
  userEthnicityIds: string[] | null;
  marketingSourceIds: string[] | null;
  organizationDescriptionIds: string[] | null;
  workSettingIds: string[] | null;
  userTenantIds: string[] | null;
  roleDescriptionId: string | null;
  sexId: string | null;
  otherSex: string | null;
  firstSeries: boolean | null;
  birthYear: number | null;
  professionStartYear: number | null;
  phone: string | null;
  isRegistrationComplete: boolean;
}

export interface GetUserById {
  getUserById: GetUserById_getUserById | null;
}

export interface GetUserByIdVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InitiateForgotPassword
// ====================================================

export interface InitiateForgotPassword_initiateForgotPassword {
  __typename: "InitiateForgotPasswordResponse";
  isSuccess: boolean;
  message: string | null;
}

export interface InitiateForgotPassword {
  initiateForgotPassword: InitiateForgotPassword_initiateForgotPassword;
}

export interface InitiateForgotPasswordVariables {
  email: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LoginUser
// ====================================================

export interface LoginUser_loginUser_userProfile {
  __typename: "UserProfile";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  roles: string;
  isRegistrationComplete: boolean;
  token: string;
}

export interface LoginUser_loginUser {
  __typename: "LoginResponse";
  isValid: boolean;
  needsPasswordReset: boolean;
  isRegistrationComplete: boolean;
  errorMessage: string | null;
  userProfile: LoginUser_loginUser_userProfile | null;
}

export interface LoginUser {
  loginUser: LoginUser_loginUser | null;
}

export interface LoginUserVariables {
  credentials: ValidateLoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchUsersByField
// ====================================================

export interface SearchUsersByField_searchUsersByField {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  userCredentialsForDisplay: string | null;
  organization: string | null;
  userRolesForDisplay: string | null;
  partnerId: string | null;
}

export interface SearchUsersByField {
  searchUsersByField: SearchUsersByField_searchUsersByField[] | null;
}

export interface SearchUsersByFieldVariables {
  search?: string | null;
  field?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchUsersByName
// ====================================================

export interface SearchUsersByName_searchUsersByName {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  userCredentialsForDisplay: string | null;
  organization: string | null;
  userRolesForDisplay: string | null;
  partnerId: string | null;
}

export interface SearchUsersByName {
  searchUsersByName: SearchUsersByName_searchUsersByName[] | null;
}

export interface SearchUsersByNameVariables {
  firstName?: string | null;
  lastName?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePassword
// ====================================================

export interface UpdatePassword_updatePassword {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface UpdatePassword {
  updatePassword: UpdatePassword_updatePassword | null;
}

export interface UpdatePasswordVariables {
  userId: string;
  password: string;
}

// ====================================================
// GraphQL mutation operation: UpdateCurrentPassword
// ====================================================

export interface UpdateCurrentPassword_updateCurrentPassword {
  __typename: "UpdateCurrentPassword";
  currentPassword: string;
  newPassword: string;
  isValid: boolean;
  errorMessage: string;
}

export interface UpdateCurrentPassword {
  updateCurrentPassword: UpdateCurrentPassword_updateCurrentPassword | null;
}

export interface UpdateCurrentPasswordVariables {
  updateCredentials: {
    currentPassword: string;
    newPassword: string;
  };
}

export interface ValidateUpdateCurrentPasswordInput {
  currentPassword: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserRoles
// ====================================================

export interface UpdateUserRoles {
  updateUserRoles: number | null;
}

export interface UpdateUserRolesVariables {
  userId: string;
  roleIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateStaffUser
// ====================================================

export interface UpdateStaffUser_updateStaffUser {
  __typename: "StaffUser";
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UpdateStaffUser {
  updateStaffUser: UpdateStaffUser_updateStaffUser | null;
}

export interface UpdateStaffUserVariables {
  user: AddOrUpdateStaffUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface UpdateUser {
  updateUser: UpdateUser_updateUser | null;
}

export interface UpdateUserVariables {
  user?: AddOrUpdateUserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ValidateForgotPassword
// ====================================================

export interface ValidateForgotPassword_validateForgotPassword_user {
  __typename: "User";
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
}

export interface ValidateForgotPassword_validateForgotPassword {
  __typename: "ValidateForgotPasswordResponse";
  isSuccess: boolean;
  codeExpired: boolean;
  message: string | null;
  user: ValidateForgotPassword_validateForgotPassword_user | null;
}

export interface ValidateForgotPassword {
  validateForgotPassword: ValidateForgotPassword_validateForgotPassword;
}

export interface ValidateForgotPasswordVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EmailCategory {
  account = "account",
  cohort = "cohort",
  hubTeam = "hubTeam",
  registration = "registration",
}

export interface AddCohortPreRegistrationInput {
  cohortId: string;
  userId?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  organization?: string | null;
  profession?: string | null;
}

export interface AddCohortRegistrationInput {
  cohortId: string;
  userId: string;
  isActive: boolean;
  isAgreed?: boolean | null;
  copiedFromWaitlist: boolean;
  registrationAnswers?: string | null;
}

export interface AddCohortToProgramInput {
  programId: string;
  internalLabel: string;
  startDate?: any | null;
  endDate?: any | null;
}

export interface AddExistingHubMemberCohortInput {
  userId: string;
  cohortId: string;
  facilitateAll?: boolean | null;
  presentAll?: boolean | null;
  panelistAll?: boolean | null;
  hubMemberCohortSessions?: HubMemberCohortSessionInput[] | null;
  credentials?: string | null;
  hide?: boolean | null;
}

export interface AddNewHubMemberCohortInput {
  firstName: string;
  lastName: string;
  email: string;
  cohortId: string;
  facilitateAll?: boolean | null;
  presentAll?: boolean | null;
  panelistAll?: boolean | null;
  sessionsAsFacilitator?: string[] | null;
  sessionsAsPresenter?: string[] | null;
  sessionsAsPanelist?: string[] | null;
  credentials?: string | null;
  hide?: boolean | null;
}

export interface AddOrUpdateCohortAttendanceInput {
  sessionAttendanceId?: string | null;
  cohortRegistrationId: string;
  sessionId: string;
  userId: string;
  present: boolean;
  notes: string;
}

export interface AddOrUpdateStaffUserInput {
  id?: string | null;
  firstName: string;
  lastName: string;
  email: string;
  password?: string | null;
  partnerId?: string | null;
}

export interface AddOrUpdateUserInput {
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  password?: string | null;
  confirmPassword?: string | null;
  otherCredential?: string | null;
  professionId?: string | null;
  secondLevelProfessionId?: string | null;
  thirdLevelProfessionId?: string | null;
  otherProfession?: string | null;
  otherEthnicity?: string | null;
  otherMarketingSource?: string | null;
  otherWorkSetting?: string | null;
  organization?: string | null;
  organizationAddress1?: string | null;
  organizationAddress2?: string | null;
  city?: string | null;
  state?: string | null;
  countyId?: string | null;
  zip?: string | null;
  provideDirectCare?: boolean | null;
  provideMedicaid?: boolean | null;
  primaryCare?: boolean | null;
  credentials?: string[] | null;
  ethnicities?: string[] | null;
  receiveEmails?: boolean | null;
  sexId?: string | null;
  otherSex?: string | null;
  organizationDescriptionIds?: string[] | null;
  marketingSourceIds?: string[] | null;
  firstSeries?: boolean | null;
  birthYear?: number | null;
  professionStartYear?: number | null;
  roleDescriptionId?: string | null;
  workSettingIds?: string[] | null;
  userTenantIds?: string[] | null;
  phone?: string | null;
  isRegistrationComplete: boolean;
}

export interface AddPartnerInput {
  name: string;
}

export interface AddProgramInput {
  name: string;
  partnerId?: string | null;
}

export interface AddResourceInput {
  programId: string;
  resourceTypeId?: string | null;
  name: string;
  displayName?: string | null;
  url?: string | null;
}

export interface AddSessionInput {
  cohortId: string;
  number?: number | null;
  title?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  active: boolean;
  learningOutcomes?: string | null;
  presenterNotes?: string | null;
  facultyNotes?: string | null;
  sendCalendarInvites: boolean;
}

export interface ApproveCohortRegistrationInput {
  id: string;
  decisionUserId: string;
}

export interface ArchiveCohortInput {
  cohortIdToArchive: string;
}

export interface CohortResourceInput {
  id: string;
  cohortId: string;
  resourceId: string;
  sessionId?: string | null;
}

export interface DenyCohortRegistrationInput {
  id: string;
  decisionUserId: string;
  denialReason?: string | null;
}

export interface DuplicateCohortInput {
  cohortIdToDuplicate: string;
  newCohortInternalLabel: string;
  transferWaitlist: boolean;
  copySessions: boolean;
  copyHubMembers: boolean;
}

export interface EditCohortInput {
  id: string;
  developmentUserId?: string | null;
  implementationUserId?: string | null;
  evaluationUserId?: string | null;
  meetingLink?: string | null;
  evaluationGoal?: string | null;
  audience?: string | null;
  agreement?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  scheduleDescription?: string | null;
  statistics?: string | null;
  registrationOpenDate?: any | null;
  registrationCloseDate?: any | null;
  archived: boolean;
  internalLabel?: string | null;
  externalLabel?: string | null;
  prelaunchDateTime?: any | null;
  projectPartner?: string | null;
  learningObjectives?: string | null;
  caseFormLink?: string | null;
  surveyLink?: string | null;
  timeZone?: string | null;
  allowPreregistration?: boolean | null;
  isOngoing: boolean;
  sessionDurationInMinutes: number;
  developmentSupportUserIds?: string[] | null;
  evaluationSupportUserIds?: string[] | null;
  implementationSupportUserIds?: string[] | null;
}

export interface HubMemberCohortSessionInput {
  hubMemberCohortId: string;
  sessionId: string;
  hubRoleId: string;
}

export interface RegistartionQuestionScaleInput {
  label?: string | null;
}

export interface RegistrationQuestionInput {
  id: string;
  title: string;
  type: string;
  name: string;
  required: boolean;
  yesNo?: RegistrationQuestionYesNoInput | null;
  options?: RegistrationQuestionOptionInput[] | null;
  isMultiSelect?: boolean | null;
  hasOtherOption?: boolean | null;
  scale?: RegistartionQuestionScaleInput[] | null;
  statements?: RegistrationQuestionStatementsInput[] | null;
}

export interface RegistrationQuestionOptionInput {
  label?: string | null;
  value?: string | null;
}

export interface RegistrationQuestionStatementsInput {
  name?: string | null;
  statement?: string | null;
}

export interface RegistrationQuestionYesNoInput {
  yesText?: string | null;
  noText?: string | null;
}

export interface SelectedUserInput {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface SendCohortEmailInput {
  cohortId: string;
  emailType: string;
  sessionId?: string | null;
  sessionOption?: string | null;
  sendSelectedUsers: SelectedUserInput[];
  subject: string;
  text: string;
  senderId: string;
  attachments?: (string | null)[] | null;
}

export interface SendEmailInput {
  recipientIds: string[];
  senderId?: string | null;
  bodyReplacementVariables?: string | null;
  subjectReplacementVariables?: string | null;
  templateName?: string | null;
  programId?: string | null;
  nonTemplateSubject?: string | null;
  nonTemplateText?: string | null;
}

export interface SendNextStepsEmailInput {
  hubMemberCohortId: string;
  subject: string;
  text: string;
}

export interface ToggleCohortRegistrationActiveInput {
  id: string;
  decisionUserId: string;
  isActive: boolean;
}

export interface UpdateEmailTemplateInput {
  id: string;
  name: string;
  template: string;
  subject: string;
}

export interface UpdateHubMemberCohortInput {
  facilitateAll?: boolean | null;
  hubMemberCohortSessions?: HubMemberCohortSessionInput[] | null;
  id: string;
  panelistAll?: boolean | null;
  presentAll?: boolean | null;
  sendEmails?: boolean | null;
  credentials?: string | null;
  hide?: boolean | null;
}

export interface UpdatePartnerInput {
  id: string;
  name: string;
}

export interface UpdateProgramEmailTemplateInput {
  id: string;
  template: string;
  subject: string;
}

export interface UpdateProgramInput {
  id: string;
  name: string;
  partnerId?: string | null;
  isArchived: boolean;
}

export interface UpdateResourceInput {
  id: string;
  resourceTypeId?: string | null;
  displayName?: string | null;
  url?: string | null;
  cohortResources?: CohortResourceInput[] | null;
}

export interface UpdateSessionInput {
  id: string;
  cohortId: string;
  number?: number | null;
  title?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  active: boolean;
  learningOutcomes?: string | null;
  presenterNotes?: string | null;
  facultyNotes?: string | null;
  sendCalendarInvites: boolean;
}

export interface ValidateLoginInput {
  email: string;
  password: string;
}

export interface WaitlistCohortRegistrationInput {
  id: string;
  decisionUserId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
