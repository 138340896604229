import { gql } from '@apollo/client';

export const LoginUserMutation_Name = 'LoginUser';
export const LoginUserMutation_Gql = gql`
  mutation LoginUser($credentials: ValidateLoginInput!) {
    loginUser(credentials: $credentials) {
      isValid
      needsPasswordReset
      isRegistrationComplete
      errorMessage
      userProfile {
        id
        firstName
        lastName
        email
        roles
        token
      }
    }
  }
`;
