import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';

import SortableTableColumn, {
  SortProperty,
  sortBy,
} from 'components/global/SortableTableColumn';
import { convertDateToTimeZoneFromUtc } from 'helpers/dateHelpers';

// Hooks
import { useDeleteResource } from '../hooks/useDeleteResource';
import { useToggleResourceArchive } from '../hooks/useToggleResourceArchive';
import { useGetFileFromS3 } from '../hooks/useGetSignedURL';

// Components
import DeleteIcon from 'components/global/Icons/DeleteIcon';
import ProgressIndicator from 'components/global/ProgressIndicator';

import EditResource from './EditResource';

import { GetResourceByProgram_getResourceByProgram } from 'models/GeneratedModels';

interface ResourceListProps {
  resources: GetResourceByProgram_getResourceByProgram[];
}

const ResourceList = ({ resources }: ResourceListProps) => {
  const { toggleArchived, loading: archivingResource } = useToggleResourceArchive();
  const { removeResourceFromS3AndDB, loading: deletingResource } = useDeleteResource();
  const { getFile } = useGetFileFromS3();
  const sortPropDefault: SortProperty = {
    prop: 'name',
    order: 'asc'
  };
  const [sortProp, setSortProp] = useState<SortProperty>(sortPropDefault);
  const [resourcesToDisplay, setResourcesToDisplay] = useState<GetResourceByProgram_getResourceByProgram[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const activeResources = (): GetResourceByProgram_getResourceByProgram[] => {
    return resources.length > 0 ? resources.filter((r) => r.archived === false) : [];
  };

  useEffect(() => {
    if (showAll) {
      setResourcesToDisplay(resources);
    } else {
      setResourcesToDisplay(activeResources());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const showAll = e.target.checked;

    setShowAll(showAll);
    if (showAll) {
      setResourcesToDisplay(resources);
    } else {
      setResourcesToDisplay(activeResources());
    }
  };

  const changeArchiveStatus = async (resourceId: string, isArchived: boolean) => {
    try {
      setIsLoading(true);
      await toggleArchived({
      variables: {
        resourceId,
        isArchived,
      },
    });
    } 
    catch (err) { console.error('Error archiving resources', err)}
    finally { 
      setIsLoading(false);
    }
  };

  const onDeleteClick = async (deleteIsConfirmed: boolean, resourceId: string) => {
    if (deleteIsConfirmed && resourceId.length > 0) {
      setIsLoading(true);
      try {
        await removeResourceFromS3AndDB(resourceId); 
      }
      catch (err) { console.error('Error archiving resources', err)}
      finally { 
        setIsLoading(false);
      }
    }
  };

  const downloadResource = async (programID: string, fileName: string) => {
    const response = await getFile(programID, fileName);

    if (response.success) {
      const binary = atob(response.success.file);
      const byteNumbers = new Array(binary.length);

      for (let i = 0; i < binary.length; i++) {
        byteNumbers[i] = binary.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.success.contentType });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log(response.errors);
    }
  }

  if (resources.length === 0) {
    return <Alert severity="info">There are currently no resources for this program</Alert>;
  }

  return (
    <>
      <ProgressIndicator isOpen={archivingResource || deletingResource || isLoading} title="Updating Resource..." />

      <Box>
        <Alert sx={{ textAlign: 'left' }} severity="info">  
          Archiving a resource will NOT remove it from the learner's view and will remain accessible by the learner. Deleting the resource will remove it from all locations.
        </Alert>

        <FormControlLabel
          control={<Checkbox checked={showAll} onChange={onCheckboxChange} />}
          label="Include Archived Resources"
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label="Resource Management">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="name"
                headerText="Resource"
                order={sortProp?.order}
              />
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="displayName"
                headerText="Name"
                order={sortProp?.order}
              />
              { /*
                <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="resourceType?.name"
                headerText="Type"
                order={sortProp?.order}
              /> */
              }
              <SortableTableColumn
                currentSort={sortProp?.prop}
                onSort={setSortProp}
                sortProperty="createdAt"
                headerText="Date Uploaded"
                order={sortProp?.order}
              />
              {showAll && 
                <SortableTableColumn
                  currentSort={sortProp?.prop}
                  onSort={setSortProp}
                  sortProperty="archived"
                  headerText="Archived"
                  order={sortProp?.order}
                />
              }
              <TableCell align="center">Type</TableCell>
              <TableCell align={showAll ? "center" : "left"}>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(resourcesToDisplay, sortProp.order ? sortProp : sortPropDefault).map((resource: GetResourceByProgram_getResourceByProgram) => (
              <TableRow key={resource.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: 10 }}>
                  {resource.resourceTypeId === '8c3fe862-46e1-11ec-82a5-13a60ed940a6' ? (
                    <Tooltip title="Open Link">
                      <Link href={resource.url ?? '#'} target="_blank">
                        <LinkIcon />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Download">
                      <Link onClick={() => downloadResource(resource.programId, resource.name)}>
                        <DownloadIcon />
                      </Link>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: 150 }}>{resource.name}</TableCell>
                <TableCell align="left" style={{ width: 150 }}>{resource.displayName ?? resource.name}</TableCell>
                <TableCell align="left" style={{ width: 75 }}>{convertDateToTimeZoneFromUtc(resource.createdAt)}</TableCell>
                {showAll && <TableCell align="center" style={{ width: 50 }}>{resource.archived ? 'Yes' : 'No'}</TableCell>}
                <TableCell align="center" style={{ width: 75 }}>{resource.resourceType?.name ?? 'set type'}</TableCell>
                <TableCell align={showAll ? "center" : "left"} style={{ width: 100 }}>
                  <EditResource resourceId={resource.id} resourceName={resource.name} />
                  {resource.archived ? (
                    <Tooltip title={`Restore ${resource.name}`}>
                      <UnarchiveOutlinedIcon
                        onClick={() => changeArchiveStatus(resource.id, false)}
                        sx={{ color: 'primary.main', ml: 2, mr: 2, cursor: 'pointer' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Archive ${resource.name}`}>
                      <ArchiveIcon
                        onClick={() => changeArchiveStatus(resource.id, true)}
                        sx={{ color: 'primary.main', ml: 2, mr: 2, cursor: 'pointer' }}
                      />
                    </Tooltip>
                  )}
                  <DeleteIcon
                    iconTooltip={`Delete ${resource.name}`}
                    dialogTitle="Delete Resource"
                    dialogText={`Are you sure you want to permanently delete ${resource.name}?`}
                    confirmText="Delete"
                    reference={resource.id}
                    onClose={onDeleteClick}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourceList;
