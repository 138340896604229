import { reactapp_APIURL } from '../../../../../helpers/tenantHelpers';

import useAxios from 'hooks/useAxios';

export function useGetFileFromS3() {
  const { get } = useAxios();

  const getFile = async (programId: string, fileName: string) => {
    let errors: any;
    let success: any;
  
    try {
      const url = `${reactapp_APIURL}/resource/getFile?programId=${programId}&fileName=${fileName}`;
      const response = await get(url);

      success = response.data;
    } catch (error: any) {
      console.error('Error uploading file:', error);

      errors = error;
    }
  
    return { success, errors };
  };  

  return { getFile };
}
