import { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProgressIndicator from 'components/global/ProgressIndicator';
import PasswordRequirements from 'components/account/Password/PasswordRequirements';
import { encrypt, validatePassword } from 'components/account/Password/Password.helpers';

import { UpdateCurrentPassword_Gql } from 'gql/user/updateCurrentPassword';
import { UpdateCurrentPassword as UpdateCurrentPasswordResult, UpdateCurrentPasswordVariables } from 'models/GeneratedModels';
import { loginLink } from 'utils/links';

interface PasswordFields {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordProps {
  userId: string;
  redirectToLogin: boolean;
}

const UpdateCurrentPassword = ({ userId, redirectToLogin }: ChangePasswordProps) => {
  const history = useHistory();

  const [errorText, setErrorText] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [password, setPassword] = useState<PasswordFields>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [savePassword, { error, loading }] = useMutation<UpdateCurrentPasswordResult, UpdateCurrentPasswordVariables>(UpdateCurrentPassword_Gql);

  const onFormElementChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setPassword({
      ...password,
      [name]: value,
    });
  };

  const savePasswordUpdate = async () => {
    setErrorText('');

    const passwordValidation = validatePassword(password.newPassword, password.confirmPassword);
    if (passwordValidation.isValid) {
      try {
        const sendCurrentPassword = encrypt(password.currentPassword);
        const sendNewPassword = encrypt(password.newPassword);
        
        const response = (
          await savePassword({
          variables: {
            updateCredentials: {
              currentPassword: sendCurrentPassword,
              newPassword: sendNewPassword,
            },
          },
          })
        ).data?.updateCurrentPassword;

        // Ensure response and data are not null or undefined
        if (!response?.isValid) {
            setErrorText(response?.errorMessage || "A valid current password is required"); // Ensure errorMessage is a string
            return; // Exit the function to prevent setting showConfirmation
        } else {
          // If isValid is true, proceed to set showConfirmation
          setShowConfirmation(true);

          if (redirectToLogin) {
            history.push(loginLink.href);
          }
        } 
      } catch (error: any) {
        console.log(error);
        setErrorText('An unexpected error occurred.');
      }
    } else {
      setErrorText(passwordValidation.message);
    }
  };

  // Function to check if all required fields are filled
  const areAllFieldsFilled = () => {
    return password.currentPassword && password.newPassword && password.confirmPassword;
  };

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Saving..." />

      <Grid container>
        <Grid xs={3}></Grid>
        <Grid xs={6}>
          <Stack spacing={2}>
            {showConfirmation && <Alert severity="success">Password has been updated</Alert>}
            <TextField
              autoFocus
              margin="dense"
              name="currentPassword"
              label="Current Password"
              type="password"
              variant="outlined"
              color="warning"
              value={password.currentPassword}
              onChange={onFormElementChange}
              required
            />
            <TextField
              autoFocus
              margin="dense"
              name="newPassword"
              label="New Password"
              type="password"
              variant="outlined"
              color="warning"
              value={password.newPassword}
              onChange={onFormElementChange}
              required
            />
            <PasswordRequirements />
            <TextField
              sx={{ mt: 2 }}
              margin="dense"
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              variant="outlined"
              color="warning"
              value={password.confirmPassword}
              onChange={onFormElementChange}
              required
            />

            {errorText.length > 0 && <Alert severity="error">{errorText}</Alert>}

            <Box sx={{ textAlign: 'right' }}>
              <Button 
                onClick={savePasswordUpdate} 
                variant="contained" 
                color="primary"
                disabled={!areAllFieldsFilled()} // Disable button if fields are not filled
              >
                Update
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid xs={3}></Grid>
      </Grid>
    </>
  );
};

export default UpdateCurrentPassword;